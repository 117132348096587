<template lang="">
<v-card class="mx-auto pa-3" max-width="700">
      <v-form @submit.prevent="addTask" class="text-center">
        <v-text-field clearable v-model="baslik" label="Başlık">
        </v-text-field>
        <v-btn color="success" @click="addTask">Yeni Başlık Ekle</v-btn>
      </v-form>
</v-card>  
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      baslik: '',
      //siteurl: 'http://localhost/vue/yapilacak'
      siteurl: 'https://yapilacak.sedatcosar.com'
    };

  },
  mounted() {
  },
  methods: {
    addTask() {
      if (this.baslik.trim() !== '') {
        axios.post(this.siteurl + "/api/baslikekle.php", {
          'baslik': this.baslik
        })
          .then(response => {
            console.log('Eklenme durumu:', response.data);
            this.gityapilacak(response.data.link)
          });

      }
    },
    gityapilacak(link) {
      this.$router.push('/yapilacak/' + link);
    }
  },
}
</script>
<style lang="">
  
</style>
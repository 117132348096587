import {
  createRouter,
  createWebHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes = [{
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title:"Başlık Ekle",
    }
  },
  {
    path: '/yapilacak/:id',
    name: 'yapilacak',
    meta: {
      title:"Yapılacak Listesi",
    },
    component: () => import( /* webpackChunkName: "yapilacak" */ '../views/YapilacakView.vue')
  },
  {
    path: '/hakkimda',
    name: 'hakkimda',
    component: () => import( /* webpackChunkName: "hakkimda" */ '../views/HakkimdaView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
    console.log(to);
    document.title = to.meta.title; 
    next(); 
});
export default router
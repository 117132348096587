<template>
  <v-card class="mx-auto" color="grey-lighten-3" max-width="448">
    <v-container fluid>
      <v-layout>

        <v-app-bar color="teal-darken-1">
          <template v-slot:image>
            <v-img gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"></v-img>
          </template>
          <v-app-bar-title>Yapılacak Listesi</v-app-bar-title>
          <v-btn icon>
            <v-icon @click="gitAnasayfa">mdi-plus-circle</v-icon>
          </v-btn>
        </v-app-bar>

        <v-main>
          <router-view></router-view>
        </v-main>

      </v-layout>
    </v-container>
  </v-card>
</template>
<script>
export default {
  methods: {
    gitAnasayfa() {
      this.$router.push("/");
    }
  },
}
</script>
<style lang="">

</style>